var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ADSpace_wrapper" },
    [
      _c(
        "el-tabs",
        { attrs: { type: "card" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "左侧广告" } },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  attrs: { "status-icon": "", "label-width": "120px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { required: true, label: "左侧广告位1" } },
                    [
                      _c(
                        "div",
                        { staticClass: "disflex flexdc left_advertising" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.LeftFitstDetail.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.LeftFitstDetail, "status", $$v)
                                },
                                expression: "LeftFitstDetail.status"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "active" } }, [
                                _vm._v("开启")
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: "forbidden" } },
                                [_vm._v("关闭")]
                              )
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "el-upload el-upload--picture-card",
                                attrs: { for: "leftfirst-adv" }
                              },
                              [
                                _c("input", {
                                  ref: "leftfirstadv",
                                  attrs: {
                                    type: "file",
                                    hidden: "",
                                    id: "leftfirst-adv",
                                    "data-type": "brandLogo",
                                    name: "upload",
                                    accept: "image/*"
                                  },
                                  on: { change: _vm.uploadImage }
                                }),
                                _vm.LeftFitstDetail.logo
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "142px",
                                        height: "143px"
                                      },
                                      attrs: { src: _vm.LeftFitstDetail.logo }
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon"
                                    })
                              ]
                            )
                          ]),
                          _c("span", { staticClass: "ml10 form_tips" }, [
                            _vm._v(
                              "建议尺寸750x876 px，支持JPG，PNG，JPEG格式，限制大小4M内"
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c("el-form-item", { attrs: { label: "外链链接" } }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder:
                              "请填写外链URL，不填写则仅展示图片，用户点击不作跳转",
                            maxlength: "200",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.LeftFitstDetail.url,
                            callback: function($$v) {
                              _vm.$set(_vm.LeftFitstDetail, "url", $$v)
                            },
                            expression: "LeftFitstDetail.url"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("el-form-item", { attrs: { label: "广告位名称" } }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请填写广告位名称",
                            maxlength: "5",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.LeftFitstDetail.name,
                            callback: function($$v) {
                              _vm.$set(_vm.LeftFitstDetail, "name", $$v)
                            },
                            expression: "LeftFitstDetail.name"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("el-divider"),
                  _c(
                    "el-form-item",
                    { attrs: { required: true, label: "左侧广告位2" } },
                    [
                      _c(
                        "div",
                        { staticClass: "disflex flexdc left_advertising" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.LeftSevDetail.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.LeftSevDetail, "status", $$v)
                                },
                                expression: "LeftSevDetail.status"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "active" } }, [
                                _vm._v("开启")
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: "forbidden" } },
                                [_vm._v("关闭")]
                              )
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "el-upload el-upload--picture-card",
                                attrs: { for: "leftsec-adv" }
                              },
                              [
                                _c("input", {
                                  ref: "leftfirstadv",
                                  attrs: {
                                    type: "file",
                                    hidden: "",
                                    id: "leftsec-adv",
                                    "data-type": "brandLogo",
                                    name: "upload",
                                    accept: "image/*"
                                  },
                                  on: { change: _vm.uploadImage2 }
                                }),
                                _vm.LeftSevDetail.logo
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "142px",
                                        height: "143px"
                                      },
                                      attrs: { src: _vm.LeftSevDetail.logo }
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon"
                                    })
                              ]
                            )
                          ]),
                          _c("span", { staticClass: "ml10 form_tips" }, [
                            _vm._v(
                              "建议尺寸750x876 px，支持JPG，PNG，JPEG格式，限制大小4M内"
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c("el-form-item", { attrs: { label: "外链链接" } }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder:
                              "请填写外链URL，不填写则仅展示图片，用户点击不作跳转",
                            maxlength: "200",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.LeftSevDetail.url,
                            callback: function($$v) {
                              _vm.$set(_vm.LeftSevDetail, "url", $$v)
                            },
                            expression: "LeftSevDetail.url"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("el-form-item", { attrs: { label: "广告位名称" } }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请填写广告位名称",
                            maxlength: "5",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.LeftSevDetail.name,
                            callback: function($$v) {
                              _vm.$set(_vm.LeftSevDetail, "name", $$v)
                            },
                            expression: "LeftSevDetail.name"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitLeftAdv }
                        },
                        [_vm._v("保 存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "弹窗广告" } },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  attrs: { "status-icon": "", "label-width": "120px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { required: true, label: "弹窗广告" } },
                    [
                      _c(
                        "div",
                        { staticClass: "left_advertising" },
                        [
                          _c("div", { staticStyle: { "margin-top": "0" } }, [
                            _vm._v("用户成功报名后弹出")
                          ]),
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.PopAdvDetail.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.PopAdvDetail, "status", $$v)
                                },
                                expression: "PopAdvDetail.status"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "active" } }, [
                                _vm._v("开启")
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: "forbidden" } },
                                [_vm._v("关闭")]
                              )
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "el-upload el-upload--picture-card",
                                attrs: { for: "pop-adv" }
                              },
                              [
                                _c("input", {
                                  ref: "popadv",
                                  attrs: {
                                    type: "file",
                                    hidden: "",
                                    id: "pop-adv",
                                    "data-type": "brandLogo",
                                    name: "upload",
                                    accept: "image/*"
                                  },
                                  on: { change: _vm.uploadImage3 }
                                }),
                                _vm.PopAdvDetail.logo
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "142px",
                                        height: "143px"
                                      },
                                      attrs: { src: _vm.PopAdvDetail.logo }
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon"
                                    })
                              ]
                            )
                          ]),
                          _c("span", { staticClass: "ml10 form_tips" }, [
                            _vm._v("建议尺寸300*300 px，支持JPG，PNG，JPEG格式")
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c("el-form-item", { attrs: { label: "外链链接" } }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder:
                              "请填写外链URL，不填写则仅展示图片，用户点击不作跳转"
                          },
                          model: {
                            value: _vm.PopAdvDetail.url,
                            callback: function($$v) {
                              _vm.$set(_vm.PopAdvDetail, "url", $$v)
                            },
                            expression: "PopAdvDetail.url"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitPop }
                        },
                        [_vm._v("保 存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "滚动公告" } },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  attrs: { "status-icon": "", "label-width": "120px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { required: true, label: "滚动公告" } },
                    [
                      _c(
                        "div",
                        { staticClass: "left_advertising" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入滚动公告",
                              maxlength: "50",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.RollAdvDetail.description,
                              callback: function($$v) {
                                _vm.$set(_vm.RollAdvDetail, "description", $$v)
                              },
                              expression: "RollAdvDetail.description"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitRoll }
                        },
                        [_vm._v("保 存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }