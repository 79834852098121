<template>
  <div class="ADSpace_wrapper">
    <el-tabs type="card">
      <el-tab-pane label="左侧广告">
        <el-form status-icon label-width="120px" class="demo-ruleForm">
          <el-form-item :required="true" label="左侧广告位1">
            <div class="disflex flexdc left_advertising">
              <el-radio-group v-model="LeftFitstDetail.status">
                <el-radio label="active">开启</el-radio>
                <el-radio label="forbidden">关闭</el-radio>
              </el-radio-group>
              <div>
                <label for="leftfirst-adv" class="el-upload el-upload--picture-card">
                  <input
                    type="file"
                    ref="leftfirstadv"
                    hidden
                    id="leftfirst-adv"
                    data-type="brandLogo"
                    name="upload"
                    accept="image/*"
                    @change="uploadImage"
                  />
                  <img
                    v-if="LeftFitstDetail.logo"
                    :src="LeftFitstDetail.logo"
                    style="width:142px;height:143px;"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </label>
              </div>
              <span class="ml10 form_tips">建议尺寸750x876 px，支持JPG，PNG，JPEG格式，限制大小4M内</span>
            </div>
          </el-form-item>
          <el-form-item label="外链链接">
            <div>
              <el-input
                v-model="LeftFitstDetail.url"
                placeholder="请填写外链URL，不填写则仅展示图片，用户点击不作跳转"
                maxlength="200"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="广告位名称">
            <div>
              <el-input
                v-model="LeftFitstDetail.name"
                placeholder="请填写广告位名称"
                maxlength="5"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item :required="true" label="左侧广告位2">
            <div class="disflex flexdc left_advertising">
              <el-radio-group v-model="LeftSevDetail.status">
                <el-radio label="active">开启</el-radio>
                <el-radio label="forbidden">关闭</el-radio>
              </el-radio-group>
              <div>
                <label for="leftsec-adv" class="el-upload el-upload--picture-card">
                  <input
                    type="file"
                    ref="leftfirstadv"
                    hidden
                    id="leftsec-adv"
                    data-type="brandLogo"
                    name="upload"
                    accept="image/*"
                    @change="uploadImage2"
                  />
                  <img
                    v-if="LeftSevDetail.logo"
                    :src="LeftSevDetail.logo"
                    style="width:142px;height:143px;"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </label>
              </div>
              <span class="ml10 form_tips">建议尺寸750x876 px，支持JPG，PNG，JPEG格式，限制大小4M内</span>
            </div>
          </el-form-item>
          <el-form-item label="外链链接">
            <div>
              <el-input
                v-model="LeftSevDetail.url"
                placeholder="请填写外链URL，不填写则仅展示图片，用户点击不作跳转"
                maxlength="200"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="广告位名称">
            <div>
              <el-input
                v-model="LeftSevDetail.name"
                placeholder="请填写广告位名称"
                maxlength="5"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitLeftAdv">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="弹窗广告">
        <el-form status-icon label-width="120px" class="demo-ruleForm">
          <el-form-item :required="true" label="弹窗广告">
            <div class="left_advertising">
              <div style="margin-top:0;">用户成功报名后弹出</div>
              <el-radio-group v-model="PopAdvDetail.status">
                <el-radio label="active">开启</el-radio>
                <el-radio label="forbidden">关闭</el-radio>
              </el-radio-group>
              <div>
                <label for="pop-adv" class="el-upload el-upload--picture-card">
                  <input
                    type="file"
                    ref="popadv"
                    hidden
                    id="pop-adv"
                    data-type="brandLogo"
                    name="upload"
                    accept="image/*"
                    @change="uploadImage3"
                  />
                  <img
                    v-if="PopAdvDetail.logo"
                    :src="PopAdvDetail.logo"
                    style="width:142px;height:143px;"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </label>
              </div>
              <span class="ml10 form_tips">建议尺寸300*300 px，支持JPG，PNG，JPEG格式</span>
            </div>
          </el-form-item>
          <el-form-item label="外链链接">
            <div>
              <el-input v-model="PopAdvDetail.url" placeholder="请填写外链URL，不填写则仅展示图片，用户点击不作跳转"></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitPop">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="滚动公告">
        <el-form status-icon label-width="120px" class="demo-ruleForm">
          <el-form-item :required="true" label="滚动公告">
            <div class="left_advertising">
              <el-input
                type="textarea"
                placeholder="请输入滚动公告"
                v-model="RollAdvDetail.description"
                maxlength="50"
                show-word-limit
                style="width:400px"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitRoll">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <!-- <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>卡片名称</span>
        <el-button style="float: right; padding: 3px 0" type="text">保存</el-button>
      </div>

      <el-form status-icon label-width="120px" class="demo-ruleForm">
        <el-form-item :required="true" label="左侧广告位1" prop="pass">
          <div class="disflex flexdc left_advertising">
            <el-radio-group v-model="radio">
              <el-radio :label="0">开启</el-radio>
              <el-radio :label="1">关闭</el-radio>
            </el-radio-group>
            <div>
              <label for="brand-logo" class="el-upload el-upload--picture-card">
                <input
                  type="file"
                  ref="brandlogo"
                  hidden
                  id="brand-logo"
                  data-type="brandLogo"
                  name="upload"
                  accept="image/*"
                  @change="uploadImage"
                />
                <img
                  v-if="baseInfo.poster"
                  :src="baseInfo.poster"
                  style="width:142px;height:142px;"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </label>
              <span class="ml10 form_tips">建议尺寸750x876 px，支持JPG，PNG，JPEG格式，限制大小4M内</span>
            </div>
            <el-input v-model="input_test" placeholder="可填写外链URL，不填写则仅展示图片，用户点击不作跳转"></el-input>
          </div>
        </el-form-item>
        <el-form-item :required="true" label="左侧广告位2" prop="pass">
          <div class="disflex flexdc left_advertising">
            <el-radio-group v-model="radio">
              <el-radio :label="0">开启</el-radio>
              <el-radio :label="1">关闭</el-radio>
            </el-radio-group>
            <div>
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="baseInfo.poster" :src="baseInfo.poster" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span class="ml10 form_tips">建议尺寸750x876 px，支持JPG，PNG，JPEG格式，限制大小4M内</span>
            </div>
            <el-input v-model="input_test" placeholder="可填写外链URL，不填写则仅展示图片，用户点击不作跳转"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-card>-->
  </div>
</template>

<script>
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

export default {
  name: "ADSpace", // 广告位设置
  components: {},
  props: {
    activityID: String
  },
  data() {
    return {
      upload: null, // 上传对象
      LeftFitstDetail: {
        description: "",
        // id: 7,
        logo: "",
        name: "",
        position: "left",
        sort: 4,
        status: "active",
        url: ""
      },
      LeftSevDetail: {
        description: "",
        // id: 7,
        logo: "",
        name: "",
        position: "left",
        sort: 4,
        status: "active",
        url: ""
      },
      PopAdvDetail: {
        description: "",
        // id: 7,
        logo: "",
        name: "",
        position: "pop",
        sort: 4,
        status: "active",
        url: ""
      },
      RollAdvDetail: {
        description: "",
        // id: 7,
        logo: "",
        name: "",
        position: "roll",
        sort: 4,
        status: "active",
        url: ""
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleAvatarSuccess() {},
    beforeAvatarUpload() {},
    // 查询广告位信息
    async getActivityAdvList() {
      try {
        const { data } = await ActivityAjax.getActivityAdvList(this.activityID);
        console.log(data);

        this.RollAdvDetail = data[3];
        this.PopAdvDetail = data[2];
        this.LeftSevDetail = data[1];
        this.LeftFitstDetail = data[0];
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.LeftFitstDetail.logo = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 上传
    async uploadImage2(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.LeftSevDetail.logo = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 上传
    async uploadImage3(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.PopAdvDetail.logo = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 确认新建或编辑左侧广告
    async submitLeftAdv() {
      let act = {
        actId: this.activityID,
        adList: []
      };
      act.adList.push(this.LeftFitstDetail);
      act.adList.push(this.LeftSevDetail);
      console.log(act);
      await ActivityAjax.saveActivityAdvList(act);
      this.$message.success("编辑左侧广告成功");
      await this.getActivityAdvList();
    },
    // 确认新建或编辑弹窗广告
    async submitPop() {
      let act = {
        actId: this.activityID,
        adList: []
      };
      act.adList.push(this.PopAdvDetail);
      console.log(act);
      await ActivityAjax.saveActivityAdvList(act);
      this.$message.success("编辑弹窗广告成功");
      await this.getActivityAdvList();
    },
    // 确认新建或编辑滚动广告
    async submitRoll() {
      let act = {
        actId: this.activityID,
        adList: []
      };
      act.adList.push(this.RollAdvDetail);
      console.log(act);
      await ActivityAjax.saveActivityAdvList(act);
      this.$message.success("编辑滚动广告成功");
      await this.getActivityAdvList();
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.getActivityAdvList();
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image"
      });
    });
  }
};
</script>
<style lang="scss" scoped>
.ADSpace_wrapper {
}
</style>
